<template>
  <ion-page class="page">
    <h1 class="mt-0">UsePencil, Inc. Privacy Policy</h1>
    <p><span class="bold">Effective Date:</span> January 1, 2020</p>
    <p><span class="bold">Last Reviewed:</span> January 1, 2020</p>
    <ol class="italic">
      <li>
        <p>Introduction</p>
      </li>
      <li>
        <p>What Does This Policy Cover?</p>
      </li>
      <li>
        <p>Information We Collect</p>
      </li>
      <li>
        <p>How We Use Your Information</p>
      </li>
      <li>
        <p>How We Share Your Information</p>
      </li>
      <li>
        <p>How We Protect Your Information</p>
      </li>
      <li>
        <p>Giving You Choice and Control: Your Preferences</p>
      </li>
      <li>
        <p>What Information of Mine Can I Access?</p>
      </li>
      <li>
        <p>What Rights Do I Have Regarding my Information?</p>
      </li>
      <li>
        <p>How Can I Have My Information Deleted?</p>
      </li>
      <li>
        <p>Additional Information for European Union Residents</p>
      </li>
      <li>
        <p>Changes to This Privacy Policy</p>
      </li>
      <li>
        <p>How to Contact Us</p>
      </li>
    </ol>
    <br />
    <p class="bold italic">1. Introduction</p>
    <br />
    <p>
      At UsePencil, Inc., we are committed to protecting your privacy and keeping your information safe. This Privacy
      Policy describes how UsePencil, Inc. and its affiliates (“Company” or “we” or “our” or "CharacterHub" or
      "CharacterHub Platform") treat information collected or provided in connection with an end user’s (“you” or “your
      or “user”) use of the services provided via our website at characterhub.com (the “Site”) or any other services
      provided ancillary to that use.
    </p>
    <br />
    <div align="left">
      <table>
        <colgroup>
          <col width="672" />
        </colgroup>
        <tbody>
          <tr>
            <td>
              <br />
              <p class="bold italic">2. What Does This Privacy Policy Cover?</p>
              <br />
              <p class="bold">Personal Information</p>
              <p>
                This Policy sets out the essential details relating to your personal data relationship with UsePencil,
                Inc.. It specifically covers the treatment of personally identifiable information (“Personal
                Information”) gathered when you are using or accessing the Site. In addition, it covers our treatment of
                any Personal Information that our business partners share with us or that we share with our business
                partners.
              </p>
              <br />
              <p>This Policy aims to:</p>
              <br />
              <ol>
                <li>
                  <p>
                    Ensure that you understand what personal data we collect about you, the reasons why we collect and
                    use it, and who we share it with;
                  </p>
                </li>
                <li>
                  <p>
                    Explain the way we use the personal data that you share with us in order to give you a great
                    experience when you are using CharacterHub; and
                  </p>
                </li>
                <li>
                  <p>
                    Explain your rights and choices concerning the personal data we collect and process about you and
                    how we will protect your privacy.
                  </p>
                </li>
              </ol>
              <br />
              <p class="bold">Third Parties</p>
              <p>
                This Privacy Policy does not apply to the practices of third parties that we do not own or control,
                including but not limited to any third-party websites, services, and applications (“Third Party
                Services”). The Site may contain, and we may send to registered users, offers from third parties or
                links to third-party websites. We encourage you to request that such third parties provide you with
                their applicable privacy policies and other terms and conditions before engaging with or making a
                purchase from them.
              </p>
              <br />
              <br />
              <p class="bold italic">3. Information We Collect</p>
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <p class="bold">Account and Profile Information.</p>
    <p>
      When you use the CharacterHub Platform, we collect the information you provide in any applicable form. If you
      create an Account or sign up to receive emails, you may provide information that could be Personal Information,
      such as your username, password, email address, financial information, or photo. In addition to using your contact
      information to send you any emails you have signed up to receive, we may also use your contact information to send
      you information about our services, but only rarely when we feel such information is important.
    </p>
    <br />
    <p>
      Your name, photo, and any other information that you choose to add to your public-facing profile will be available
      for viewing to users of the Site. Once you create a profile, other users will be able to see in your profile and
      certain information about your activity on the CharacterHub Platform, such as text, stories, and poems that you
      post, your followers and who you follow, topics of interest to you, the information you list as credentials, and
      your edits to your Content.
    </p>
    <br />
    <p class="bold">User Content.</p>
    <p>
      We collect and store the information and content that you post to the CharacterHub Platform, including your
      reactions, shoutouts, photos, and comments. Unless you have posted certain content anonymously, Your Content, date
      and time stamps, and all associated comments are publicly viewable on the CharacterHub Platform, along with your
      name. This also may be indexed by search engines and be republished elsewhere on the Internet per our Terms of
      Service. For more information about what you can change, see the below section on Your Choices.
    </p>
    <br />
    <p class="bold">Integrated Service Provider and Linked Networks.</p>
    <p>
      You can elect to sign in or sign up to the CharacterHub Platform through a linked network (i.e., Facebook or
      Twitter), and you can connect your existing CharacterHub account with certain third party networks like Facebook,
      Twitter (each a "Linked Network"). If you elect to sign up through or connect a Linked Network, we receive certain
      profile and account information about you from the Linked Network. These Linked Networks may also appear in your
      profile so that people can find you on these Linked Networks. You should consult their respective privacy policies
      for information about their practices.
    </p>
    <br />
    <p>
      You may elect to use information from the Linked Network to populate your profile on the CharacterHub Platform and
      help you find and follow your contacts. You may also elect to connect, make and receive payments through third
      party networks ("Integrated Service Provider"); if you do so, you will be allowing us to passively receive from
      the ISP your login information and other user data for payment purposes.
    </p>
    <br />
    <p class="bold">Communications.</p>
    <p>
      When you communicate with us (via email, phone, through the CharacterHub Platform, or otherwise), we may maintain
      a record of your communication.
    </p>
    <br />
    <p class="bold">IP Address Information and Other Information:</p>
    <p>
      We collect browsing information - such as IP address and location, date and time stamp, user agent, CharacterHub
      cookie ID (if applicable), URL, unique advertising or content identifiers (if applicable) and time zone, and other
      information about user activities on the CharacterHub Platform, as well as on third party sites and services that
      have embedded our CharacterHub pixels ("Pixels"), widgets, plug-ins, buttons, or related services. We may also
      receive information about you from third parties, such as other users, partners (including ad partners), or our
      affiliated companies.
    </p>
    <p class="bold">Information Collected Automatically About your activity.</p>
    <p>
      When you use the Site, we automatically gather certain non-personally identifiable information from you. We and
      our third-party providers use cookies, pixel tags, local storage objects, and other tracking technologies to
      automatically collect information about your activities, such as your searches, page views, date and time of your
      visit, and other information about your use of the CharacterHub Platform. We also receive information when you
      view content on or otherwise interact with the CharacterHub Platform, even if you have not created an account.
      This includes usage information, such as when, how often, and for how long you use the Site and the content you
      submit and view. Below, we provide a summary of these activities.
    </p>
    <br />
    <ul>
      <li>
        <p>
          <span class="bold">Cookies.</span> These are small files with a unique identifier that are transferred to your
          browser through our websites. They allow us to remember users who are logged in, to understand how users
          navigate through and use the CharacterHub Platform, and to display personalized content and targeted ads
          (including on third party sites and applications).
        </p>
      </li>
    </ul>
    <br />
    <ul>
      <li>
        <p>
          <span class="bold">Pixels, web beacons, clear GIFs.</span> These are tiny graphics with a unique identifier,
          similar in function to cookies, which we use to track the online movements of users of the CharacterHub
          Platform and the web pages of users of our Ad Services and to personalize content. We also use these in our
          emails to let us know when they have been opened or forwarded so that we can gauge the effectiveness of our
          communications.
        </p>
      </li>
    </ul>
    <br />
    <p>
      Most browsers have an option for turning off the cookie feature. We strongly recommend that you leave cookies
      active because they enable you to take advantage of the most attractive features of the Site.
    </p>
    <br />
    <p>All or partial advertising on this Website or App is managed by Playwire LLC. If Playwire publisher advertising
      services are used, Playwire LLC may collect and use certain aggregated and anonymized data for advertising
      purposes. To learn more about the types of data collected, how data is used and your choices as a user, please
      visit <a href="https://www.playwire.com/privacy-policy">https://www.playwire.com/privacy-policy</a>.</p>
    <br />
    <p class="bold">Analytics Services &amp; Third Parties.</p>
    <p>
      We use certain third party services to assist us in analysis, auditing, research, and reporting regarding the Site
      (like Google Analytics). These third parties may use web logs or web beacons, and they may set and access cookies
      on your computer or other devices. The third-party analytics companies we work with may combine the information
      collected with other information they have independently collected from other websites or other online products
      and services. We do not control when or how third parties place cookies on your computer. Their collection and use
      of information is subject to their privacy policies.
    </p>
    <br />
    <p>Analytics Services Available Opt Out Links:</p>
    <br />
    <p>○ Google Analytics and Google Optimize</p>
    <p>
      ■
      <a rel="nofollow" class="link" href="https://tools.google.com/dlpage/gaoptout">
        https://tools.google.com/dlpage/gaoptout
      </a>
    </p>
    <br />
    <div align="left">
      <br />
      <p class="italic bold">4. How We Use Your Information</p>
      <br />
    </div>
    <p>
      We do not sell your data, such as your name and contact information to third parties. We may use or disclose the
      personal information we collect for one or more of the following purposes:
    </p>
    <br />
    <ul>
      <li>
        <p>To provide the features and functionality of the Site to you.</p>
      </li>
      <li>
        <p>To send you e-mails regarding updates or modifications to the Site.</p>
      </li>
      <li>
        <p>To process your purchases of or requests for products and services;</p>
      </li>
      <li>
        <p>
          To send you marketing and promotional emails concerning our products and services (to the extent you do not
          opt out of such emails).
        </p>
      </li>
      <li>
        <p>
          To analyze collected information relating to your use of the Site to help us improve the Site and develop and
          improve other products and services.
        </p>
      </li>
      <li>
        <p>
          To help troubleshoot problems, provide you with required notices, enforce our Terms of Service or to alert you
          to changes in our policies or agreements that may affect your use of the Site.
        </p>
      </li>
      <li>
        <p>
          To tailor the content and information that we may send to you in the CharacterHub Platform, to suggest
          followers and content, to offer location customization, and personalized help and instructions, and to
          otherwise personalize your experiences.
        </p>
      </li>
      <li>
        <p>
          To gather metrics to better understand how users’ access and use the CharacterHub Platform; to evaluate and
          improve the CharacterHub Platform, including the Ad Services and personalization, and to develop new products
          and services.
        </p>
      </li>
      <li>
        <p>
          To comply with legal obligations, as part of our general business operations, and for other business
          administration purposes.
        </p>
      </li>
      <li>
        <p>
          Where we believe necessary to investigate, prevent or take action regarding illegal activities, suspected
          fraud, situations involving potential threats to the safety of any person, or violations of our Terms of
          Service or this Privacy Policy.
        </p>
      </li>
    </ul>
    <p>
      We will not collect additional categories of personal information or use the personal information we collect for
      materially different, unrelated, or incompatible purposes without providing you notice.
    </p>
    <br />
    <div align="left">
      <p class="bold italic">5. How We Share Your Information</p>
    </div>
    <p>
      We disclose the personally identifiable and non-personally identifiable information that we collect about you as
      follows:
    </p>
    <br />
    <ul>
      <li>
        <p>
          We may provide advertisers and other third parties with aggregated, non-personally identifiable information
          about our user base and its usage patterns. The Company uses contractors and third-party service providers to
          provide the Site to our users and to help us provide services in connection with the Site. Such third parties
          may receive information from us for use for such purposes.
        </p>
      </li>
      <li>
        <p>
          We may report aggregated, de-identified data relating to activity on this Site to third parties or the general
          public. We may combine such data with data obtained from third-party sources to generate such reports.
        </p>
      </li>
      <li>
        <p>
          We will use and disclose information where we, in good faith, believe that the law or legal process (such as a
          court order, search warrant or subpoena) requires us to do so; to investigate, prevent, or take action
          regarding illegal activities, suspected fraud, or violations of our terms and conditions; or in other
          circumstances where we believe is necessary to protect the rights or property of Company, our users, and third
          parties.
        </p>
      </li>
      <li>
        <p>
          We may disclose or transfer information, including personal information, as part of any merger, sale, and
          transfer of our assets, acquisition, or restructuring of all or part of our business, bankruptcy, or similar
          event.
        </p>
      </li>
      <li>
        <p>
          Your Content, including your name, profile picture, profile information, and certain associated activity
          information, is available to other users of the CharacterHub Platform and may be viewed publicly. Public
          viewing includes availability to non-registered visitors and can occur when users share Your Content across
          other sites or services. Also, Your Content may be indexed by search engines.
        </p>
      </li>
    </ul>
    <br />
    <p>
      See the section below about Giving You Choice and Control for information about how you may change how certain
      information is shared or viewed by others.
    </p>
    <br />
    <p class="italic bold">6. How We Protect Your Information</p>
    <br />
    <p>
      We follow generally accepted standards to protect the personal information submitted to us both during
      transmission and after it is received. The information we collect about you is stored in limited-access servers,
      and we maintain reasonable safeguards to protect the security of these servers and your information.
    </p>
    <br />
    <p>
      Your personal information is important to you, and protecting that information is important to us. While we have
      implemented safeguards to protect the information we collect, no website or Internet transmission is completely
      secure, so we urge you to take steps to keep your personal information safe. Be sure to always choose a strong
      password and keep it private, log out of your user account, and always close your web browser when finished using
      the CharacterHub Platform on a shared or unsecured device
    </p>
    <br />
    <br />
    <div align="left">
      <p class="italic bold">7. Giving You Choice and Control: Your Preferences</p>
    </div>
    <p>
      You may always control the types of notifications and communications we send, limit the information shared within
      the Site about you, and otherwise amend certain privacy settings. Here is further information about some of your
      choices:
    </p>
    <br />
    <ul>
      <li>
        <p>
          <span class="bold">Your Content.</span> You may edit or delete the stories that you post at any time. Any
          deleted content will be removed from third party sites from which it has been shared via CharacterHub’s
          standard sharing features; however, we have no control over deletions or changes to Your Content if it has
          been shared manually by others or as a video. When you make edits to Your Content, other users will be able to
          see the history of those edits in your profile activity and on content edit logs.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Access and Amend Your Information</span> You may update or correct your account information
          at any time by logging in to your account.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Emails and Communications.</span> When you join the CharacterHub Platform by signing up for
          an account or creating a profile, as part of the service, you will receive the CharacterHub digest containing
          content that we believe may match your interests. You can manage your email and notice preferences in your
          account profile settings, under your Emails and Notifications settings. If you opt-out of receiving emails
          about recommendations or other information we think may interest you, we may still send you transactional
          e-mails about your account or any services you have requested or received from us. Third parties may comment
          on your postings within the CharacterHub Platform. In your profile, under your Privacy Settings, you can
          adjust whether to allow people to comment on your answers and posts.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Topics.</span> You can change topics that you follow or that your profile lists as areas
          that you know about.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Delete Your Account.</span> If you choose to Delete Your Account by emailing
          <a rel="nofollow" class="link" href="mailto:admin@characterhub.com">admin@characterhub.com</a>, then all of
          Your Content will be removed from public visibility on the CharacterHub Platform, and it may not be restored
          by us, even if you change your mind.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Linked Networks.</span> You may connect or disconnect your Linked Networks, such as
          Facebook, through the settings, and you may access, amend, and delete much of your profile information through
          your profile settings. Once you disconnect a Linked Network, we will not receive information from that Linked
          Network going forward; unless you choose to reconnect it. You may also control whether the Linked Network is
          visible in your profile.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Transferring your Data</span> CharacterHub is headquartered in the United States and has
          operations, entities, and service providers in the United States and throughout the world. As such, we and our
          service providers may transfer your personal information to, or access it in, jurisdictions (including the
          United States) that may not provide equivalent levels of data protection as your home jurisdiction. We will
          take steps to ensure that your personal information receives an adequate level of protection in the
          jurisdictions in which we process it.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Children Under 13.</span> If you are a parent and believe that your child under the age of
          13 has used the Site and provided personally identifiable information to us through the Site, please contact
          us at admin@characterhub.com, and we will work to delete that Site account and any such personally
          identifiable information.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Links to Other Websites.</span> The CharacterHub Platform may contain links to third party
          sites or online services. We are not responsible for the practices of such third parties, whose information
          practices are subject to their policies and procedures, not to this Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          <span class="bold">Choices Regarding Third-Party Offers.</span> To the extent we send you commercial e-mails
          regarding third-party marketing or promotional materials, we will give you the ability to opt-out of receiving
          such e-mails in accordance with applicable law. We will not provide your personally identifiable information
          to third parties for their own marketing purposes without your consent.
        </p>
      </li>
    </ul>
    <br />
    <p class="italic bold">8. What Information of Mine Can I Access?</p>
    <br />
    <p>
      If you are a registered user, you can access information associated with your Account by logging into the Site.
      Registered and unregistered users can access and delete cookies through their web browser settings.
    </p>
    <br />
    <p>
      <span class="bold underline">California Residents -.</span> If you are a resident of California, you have the
      right to request a list of third parties to whom we have disclosed your personally identifiable information for
      their direct marketing purposes during the preceding year, pursuant to California Civil Code Section 1798.83.
    </p>
    <br />
    <p>
      To make such a request, please send an email to
      <a rel="nofollow" class="link" href="mailto:admin@characterhub.com">admin@characterhub.com</a>
      or write to us at 1259 El Camino Real #158 Menlo Park, CA 94025. We are only required to respond to one request
      per customer each year.
    </p>
    <div align="left">
      <p class="bold italic">9. What Rights Do I Have Regarding My Information?</p>
    </div>
    <br />
    <p>
      You have the right to request that we delete any of your personal information that we collected from you and
      retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will
      delete (and direct our service providers to delete) your personal information from our records, unless an
      exception applies.
    </p>
    <br />
    <p>
      We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:
    </p>
    <ul>
      <li>
        <p>
          Complete the transaction for which we collected the personal information, provide a good or service that you
          requested, take actions reasonably anticipated within the context of our ongoing business relationship with
          you, or otherwise perform our contract with you.
        </p>
      </li>
      <li>
        <p>
          Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute
          those responsible for such activities.
        </p>
      </li>
      <li>
        <p>Debug products to identify and repair errors that impair existing intended functionality.</p>
      </li>
      <li>
        <p>
          Ensure the right of another consumer to exercise their free speech rights or exercise another right provided
          for by law.
        </p>
      </li>
      <li>
        <p>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</p>
      </li>
      <li>
        <p>
          Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that
          adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render
          impossible or seriously impair the research’s achievement if you previously provided informed consent.
        </p>
      </li>
      <li>
        <p>
          Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship
          with us.
        </p>
      </li>
      <li>
        <p>Comply with a legal obligation.</p>
      </li>
      <li>
        <p>
          Make other internal and lawful uses of that information that are compatible with the context in which you
          provided it. ‌
        </p>
      </li>
    </ul>
    <p>
      Please be aware to the extent you decline to share certain personally identifiable information with us, and we may
      not be able to provide some or all of the features and functionalities found on the Site.
    </p>
    <br />

    <p class="bold italic">10. How Can I Have My Information Deleted?</p>
    <br />
    <p>To delete all data associated with your account, please e-mail us at admin@characterhub.com</p>
    <ul>
      <li>
        <p>
          You can opt-out of cookies by going to
          <a rel="nofollow" class="link" href="https://characterhub.com/cookies"> https://characterhub.com/cookies </a>
        </p>
      </li>
      <li>
        <p>
          Find details on how to delete your account by logging into CharacterHub and going to
          <a rel="nofollow" class="link" href="https://characterhub.com/account"> https://characterhub.com/account </a>
        </p>
      </li>
    </ul>
    <p>
      Please note that while changes to your profile information are reflected promptly in active user databases, our
      servers may retain previously provided information for a short period of time. You may also make several other
      adjustments to settings or the display of information about you, as described in more detail in the previous
      section about Your Choices.
    </p>
    <p>
      Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to
      your personal information. We cannot respond to your request or provide you with personal information if we cannot
      verify your identity or authority to make the request and confirm the personal information relates to you.
    </p>
    <p class="center-text bold italic">Response Timing and Format</p>
    <br />
    <p>
      We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require
      more time, we will inform you of the reason and extension period in writing.
    </p>
    <br />
    <div align="left">
      <p class="center-text bold italic">Non-Discrimination</p>
    </div>
    <br />
    <p>We will not discriminate against you for exercising any of your rights. Unless permitted by law, we will not:</p>
    <ul>
      <li>
        <p>Deny you goods or services.</p>
      </li>
      <li>
        <p>
          Charge you different prices or rates for goods or services, including through granting discounts or other
          benefits, or imposing penalties.
        </p>
      </li>
      <li>
        <p>Provide you a different level or quality of goods or services.</p>
      </li>
    </ul>
    <div align="left">
      <p class="bold italic">11. Additional Information for European Union Residents</p>
      <br />
      <p class="bold">Payment Processors</p>
      <p>
        CharacterHub provides Services in conjunction with several payment processors, including Stripe, Paypal, Google
        Play, and Apple Pay, through which users may pay for CharacterHub memberships or receive payment based on
        participation in our Partner program. Those companies acting as payment processors may collect and store
        personal data related to your billing information and history to provide their services and may collect and
        store personal data and business data to prevent fraud and other abuse.
      </p>
      <br />
      <p>
        When you delete your CharacterHub account, CharacterHub deletes your data as outlined in this document. However,
        to delete your payment or billing information, you will need to do so with your payment provider, as
        CharacterHub only has minimal secure access to those records as needed to provide the services.
      </p>
      <br />
      <p class="bold">Potential Consequences of Processing</p>
      <p>
        By creating an account on CharacterHub, users may make certain personal data about themselves public and
        accessible to others on their profile and through network interactions. This may, in some cases, constitute
        special category protected data which is considered manifestly made public by the user. Due to the public nature
        of information posted to CharacterHub, it may be possible for third parties to derive identifying personal data
        from posts, whether by reading, inference, supplemental research, or automated extraction and analysis.
      </p>
      <p>
        Users are free to use their real name and information, or a pseudonym of their choosing, for their account.
        Users may also choose to use the service without posting data or engaging in network interactions. However, if
        you do not agree with and accept the risks of such usage, you may not use the services.
      </p>
      <br />
      <p class="bold">Cross-border Transfers</p>
      <p>
        CharacterHub is hosted in the United States. By using CharacterHub Services, you authorize CharacterHub to
        transfer, store, and use your information in the United States and any other country where we operate. Where
        your data is disclosed to our processors, it is subject by contract to at least the same level of data
        protection as that set out in this statement.
      </p>
      <br />
      <p class="bold">Retention</p>
      <p>
        CharacterHub retains personal data associated with your account for the lifetime of your account. If you would
        like to delete your personal information, you can delete your account at any time by emailing
        admin@characterhub.com. Deleted account profile pages will yield an error 404 “file not found” page, immediately
        upon initiating deletion, and will become unrecoverable in our system after a period of fourteen days. It may
        take several additional days for your data to be de-indexed from search engines, depending on those search
        engines’ practices, over which CharacterHub may have limited or no control. To delete your payment or billing
        information, you will need to do so with your payment provider, as CharacterHub only has minimal secure access
        to those records as needed to provide the services.
      </p>
      <br />
      <p class="bold">Rights of Data Subjects</p>
      <p>
        If you sign up for a CharacterHub account, you may, at any time request an export of your personal information
        by emailing
        <a rel="nofollow" class="link" href="mailto:admin@characterhub.com"> admin@characterhub.com </a>
        .
      </p>
      <br />
      <p>
        You may correct information associated with your account from the Settings page, and the Customize Your
        Interests page to update your interests. You may withdraw consent by deleting your account at any time through
        emailing
        <a rel="nofollow" class="link" href="mailto:admin@characterhub.com"> admin@characterhub.com </a>
        , which will erase your personal information completely within 14 days (except to the extent CharacterHub is
        prevented by law from deleting your information).
      </p>
      <br />
      <p>
        You may object at any time to the use of your data by contacting
        <a rel="nofollow" class="link" href="mailto:admin@characterhub.com"> admin@characterhub.com </a>
        .
      </p>
      <p>
        If your complaint relates to alleged misuse of your personal data by a third party, it may result in the
        suspension of that post or account in keeping with relevant law, public interest, our contractual obligations,
        and the rights of expression and access to information of others.
      </p>
      <br />
      <p>
        For (i) data access and portability; (ii) data correction; (iii) data deletion; and (iv) withdrawal of consent
        or objection to processing, including, in limited circumstances, the right to ask CharacterHub to stop
        processing your personal data, with some exceptions, please email admin@characterhub.com
      </p>
      <br />
      <p>
        You may, at any time lodge a complaint regarding the processing of your personal data by CharacterHub with the
        Supervisory Authority of your EU member state.
      </p>
      <br />
      <br />
      <p class="bold italic">12. Changes to Our Privacy Notice</p>
      <br />
    </div>
    <br />
    <p>
      We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this
      privacy notice, we will post the updated notice on the Website and update the notice’s effective date. Your
      continued use of our Website following the posting of changes constitutes your acceptance of such changes.
    </p>
    <br />
    <div align="left">
      <p class="bold italic">13. Contact Information</p>
    </div>
    <br />
    <p>
      If you have any questions or comments about this notice, how UsePencil, Inc. and its affiliates collect and use
      your information as described in the Privacy Policy, your choices and rights regarding such use, or wish to
      exercise your rights under the law, you may contact us at:
    </p>
    <br />
    <p>
      <span class="bold">Email:</span>
      <a rel="nofollow" class="link" href="mailto:admin@characterhub.com">admin@characterhub.com</a>
    </p>
    <br />
    <p>or</p>
    <br />
    <p class="bold">Postal Address:</p>
    <br />
    <p>UsePencil, Inc.</p>
    <p>Attn: CharacterHub Team</p>
    <p>1259 El Camino Real #158</p>
    <p>Menlo Park, CALIFORNIA (CA) 94025</p>
    <br />
  </ion-page>
</template>

<script lang="ts" setup></script>
